<script lang="ts" setup>
import {
  ref, reactive, onMounted, onUnmounted,
} from 'vue'
import { ElMessage } from 'element-plus'
import { useRouter } from 'vue-router'
import axios from 'axios'
import { useStore } from '@/store/index'
import {
  getPage,
  getHotPage,
} from '@/api'

let router = useRouter()
const vuex = useStore()
const box3index = ref(0)
const appraiseList = ref([{
  text: 'TED加速非常有效，演讲视频加载很快！收费正常价格！网络一点都不卡！用了一段时间无法加速，然后邮件反馈了一下，客服那边很快就反馈让我更新，更新后就好了，客服还在一直邮件跟进后续使用情况。',
  name: '爱学习的小熊',
  time: '2023-06-28  21:32:33',
}, {
  text: '刚用雷电加速器的时候还只有APP，因为平时和海外的部门要开视频会议APP比较麻烦，所以反馈希望出PC端，没想到很迅速，过了一个月发现出PC端了哈哈哈，很棒，以后视频会议很方便了。',
  name: '每天都很忙',
  time: '2023-03-18  21:32:33',
}, {
  text: '海外留学被地区限制搞的欲哭无泪，现在终于能看番听歌啦！感谢雷电加速器！不限速太良心！刚出的庆余年也看上了，我已经推荐给我身边的留学党了，哈哈。',
  name: 'Derek',
  time: '2023-06-12  21:32:33',
}, {
  text: '在海外工作访问公司的国内服务器，必须要用这款加速器。另外平时访问富途牛牛、通达信、大智慧等软件都非常快速稳定，可以说是办公必备了。',
  name: 'Ashley',
  time: '2023-01-15  21:32:33',
}, {
  text: '我平时比较喜欢玩国际服的一些游戏，延迟太高了没法玩，用过一些加速器也很卡，朋友给我推荐了雷电加速器，用起来延迟降低了很多，基本不会出现卡顿，打起团来也非常的丝滑。',
  name: 'Eric',
  time: '2023-09-07  21:32:33',
}, {
  text: '亲测效果很好，一开始买了个不知名加速器，也不确定好不好用，不过用了一段时间觉得蛮好用，特地来打个好评，希望能帮上各位，主要是安装在pad上给孩子海外平台学英语，很方便。',
  name: 'Sunny',
  time: '2023-03-02  21:32:33',
}])
const dl: any = reactive({
  show: false,
  type: 0,
  src: '',
  code: '',
})
const download = (filename: any, url: any) => {
  let a = document.createElement('a')
  a.style.display = 'none' // 创建一个隐藏的a标签
  a.download = filename
  a.href = url
  document.body.appendChild(a)
  a.click() // 触发a标签的click事件
  document.body.removeChild(a)
}
const handleDownload = async (val: number) => {
  dl.type = val
  dl.code = ''
  dl.src = `${import.meta.env?.ENV_BASE_API}sp-version-manage/captcha?t=${Date.now()}`
  dl.show = true
}
const changeCaptcha = async () => {
  dl.src = `${import.meta.env?.ENV_BASE_API}sp-version-manage/captcha?t=${Date.now()}`
}
const confirmDownload = async () => {
  if (!dl.show) return
  if (!dl.code) {
    ElMessage.error('请输入验证码！')
    return
  }
  const res = await axios.get(`${import.meta.env?.ENV_BASE_API}sp-version-manage/captchaVerify/${dl.code}`, { withCredentials: true })
  if (res.data.code === 200) {
    let i = vuex.$state.versionList.findIndex((e) =>
      e.plantForm === dl.type)
    if ([2, 4].includes(dl.type)) window.open(vuex.$state.versionList[i].downloadLink)
    else {
      download('雷电加速器', vuex.$state.versionList[i].downloadLink)
      ElMessage.success('已开始下载！')
    }
    dl.show = false
  } else {
    ElMessage.error('验证码错误！')
  }
}
const keyDown = (e: { keyCode: number }) => {
  if (e.keyCode === 13 || e.keyCode === 100) {
    confirmDownload()
  }
}
onMounted(() => {
  document.addEventListener('keydown', keyDown)
})
onUnmounted(() => {
  document.removeEventListener('keydown', keyDown, false)
})
const todown = () => {
  const main = document.getElementById('app')
  if (main) { main.scrollTop = 0 }
  // document.getElementById('app').scrollTop = 0
  router.push('/')
}
const searchForm = reactive({
  page: 1,
  limit: 10,
  newspaperTypeId: '',
  source: 10,
})
const infoList: any = ref([])
const hotList: any = ref([])
const getList = () => {
  getPage(searchForm).then((res) => {
    if (res.code === 200) {
      infoList.value = res.data.records.slice(0, 2)
    }
  })
  getHotPage({ source: 10, count: 2 }).then((res) => {
    if (res.code === 200) {
      hotList.value = res.data.records
    }
  })
}
getList()
</script>
<template>
  <div class="box1">
    <div class="text1">雷电加速 一键畅连</div>
    <div class="text2">延迟更低 网速更快 畅连无碍</div>
    <div class="text3">不限流量 延迟低 低抖动</div>
    <div class="tag">
      <div class="box" @click="handleDownload(2)">
        <img class="img" src="@/assets/images/apple_nor.png">
        <img class="img hover" src="@/assets/images/apple_sel.png">
        iOS版下载
      </div>
      <div class="box" @click="handleDownload(1)">
        <img class="img" src="@/assets/images/android_nor.png">
        <img class="img hover" src="@/assets/images/android_sel.png">
        Android版下载
      </div>
      <div class="box" @click="handleDownload(3)">
        <img class="img" src="@/assets/images/win_nor.png">
        <img class="img hover" src="@/assets/images/win_sel.png">
        Windows版下载
      </div>
      <div class="box" @click="handleDownload(4)">
        <img class="img" src="@/assets/images/macos_nor.png">
        <img class="img hover" src="@/assets/images/macos_sel.png">
        MacOS版下载
      </div>
    </div>
  </div>
  <div class="box2">
    <div class="text1">优质加速能力 服务于用户</div>
    <div class="text2">为每一位使用雷电加速器的用户保驾护航</div>
    <div class="text3">
      请注意：雷电加速器仅支持中国大陆地区可以访问的网络加速
    </div>
    <div class="tag">
      <div class="box">
        <div class="text">
          <div class="number">01</div>
          <div class="title">
            全球网络稳定加速是<span style="color: #00665d">保障</span>
          </div>
          <div class="content">
            优选国际网络专线，畅享全球网络连接，稳定才是硬实力
          </div>
        </div>
        <img class="img" src="@/assets/images/image_guarantee.png">
      </div>
      <div class="box">
        <img class="img" src="@/assets/images/image_technology.png">
        <div class="text" style="text-align: right">
          <div class="number">02</div>
          <div class="title">
            智能加速是<span style="color: #00665d">真技术</span>
          </div>
          <div class="content">
            专享智能路由技术，智能优选最佳线路加速，网络线路连接不用愁
          </div>
        </div>
      </div>
      <div class="box">
        <div class="text">
          <div class="number">03</div>
          <div class="title">
            自研加密技术是<span style="color: #00665d">安全</span>
          </div>
          <div class="content">
            自研加密技术，保护用户的网络使用安全，冲浪还是要保护自身网络信息安全
          </div>
        </div>
        <img class="img" src="@/assets/images/image_secure.png">
      </div>
    </div>
  </div>
  <div class="box3">
    <div class="text1">跨境解决方案 用雷电 就够了</div>
    <div class="text2">
      可以满足你对网络的各种需求，支持视频、游戏、直播和办公等加速
    </div>
    <div class="tag">
      <div class="box" :class="{ select: box3index == 0 }" @click="box3index = 0">
        <img style="width: 101px; height: 124px; object-fit: contain" src="@/assets/images/icon_shop.png">
        <div class="text">
          <div class="title">跨境电商</div>
          <div class="content">
            助力跨境电商，网络加速如虎添翼！无论你身在何地，我们都能为你提供快速、稳定的网络连接，让你在跨境电商的海洋中畅游无阻。告别卡顿和延迟，尽享流畅的购物体验。让跨境电商更具活力。
          </div>
        </div>
      </div>
      <div class="box" :class="{ select: box3index == 1 }" @click="box3index = 1">
        <img style="width: 77px; height: 124px; object-fit: contain" src="@/assets/images/icon_game.png">
        <div class="text">
          <div class="title">游戏加速</div>
          <div class="content">支持多端：手游加速器 网游加速器 端游加速器</div>
          <div class="content">支持各大热门游戏：王者荣耀加速器，吃鸡加速器，黑神话悟空等热门steam游戏加速器</div>
        </div>
      </div>
      <div class="box" :class="{ select: box3index == 2 }" @click="box3index = 2">
        <img style="width: 94px; height: 124px; object-fit: contain" src="@/assets/images/icon_education.png">
        <div class="text">
          <div class="title">教育加速</div>
          <div class="content">
            助力国际教育，连接知识无界。无论你身在何处，都能畅快访问全球优质教育资源，与世界各地的学生和老师实时互动。
          </div>
        </div>
      </div>
      <div class="box" :class="{ select: box3index == 3 }" @click="box3index = 3">
        <img style="width: 118px; height: 124px; object-fit: contain" src="@/assets/images/icon_office.png">
        <div class="text">
          <div class="title">办公加速</div>
          <div class="content">
            国际办公的得力助手！快速稳定的网络连接，让你与全球团队紧密协作，文件传输、视频会议畅通无阻。开启你的高效办公新时刻。
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="box4">
    <div class="text1">回国解决方案 毫秒必争</div>
    <div class="text2">雷电回国 一键轻松解决版权和地域限制问题</div>
    <div class="text3">
      雷电加速器一键回国，助力海外华人和留学游子无障碍畅享国内资源，轻松解决因地域限制导致的网页无法打开、音视频无法播放或购物受限等问题
    </div>
    <div class="tag">
      <div class="box">
        <div>
          <div class="title">看视频听音乐</div>
          <div class="text">
            解决因地域限制导致的音乐视频APP无法播放的问题。畅玩网易云、QQ音乐，畅享爱奇艺、优酷、bilibili、腾讯视频，秒开1080P。
          </div>
        </div>
        <img class="img" src="@/assets/images/icon_video.png">
      </div>
      <div class="box">
        <div>
          <div class="title">购物达人</div>
          <div class="text">
            轻松访问国内购物软件，可以快速定位国内商品，满足用户随时随地浏览或购买国内商品。
          </div>
        </div>
        <img class="img" src="@/assets/images/icon_shop(1).png">
      </div>
      <div class="box">
        <div>
          <div class="title">直播语音</div>
          <div class="text">
            加速斗鱼、虎牙、YY语音、抖音直播、淘宝直播等。高速节点，保障画面稳定流畅不卡顿。
          </div>
        </div>
        <img class="img" src="@/assets/images/icon_live.png">
      </div>
      <div class="box">
        <div>
          <div class="title">办公学习</div>
          <div class="text">
            加速网页，保障你能正常访问国内教育网站和办公软件进行线上办公，一键加速同花顺、大智慧等。
          </div>
        </div>
        <img class="img" src="@/assets/images/icon_study.png">
      </div>
    </div>
  </div>
  <div class="box5">
    <div class="text1">使用方法</div>
    <div class="text2">简单三步，轻松加速</div>
    <div class="tag">
      <div class="box">
        <div class="num">1</div>
        <img class="img" src="@/assets/images/icon_register.png">
        <div class="down" @click="todown">下载安装</div>
      </div>
      <img class="arrow" src="@/assets/images/icon_use_arrow.png">
      <div class="box">
        <div class="num">2</div>
        <img class="img" src="@/assets/images/icon_login.png">
        <div class="text">注册登录</div>
      </div>
      <img class="arrow" src="@/assets/images/icon_use_arrow.png">
      <div class="box">
        <div class="num">3</div>
        <img class="img" src="@/assets/images/icon_ac.png">
        <div class="text">一键智能加速</div>
      </div>
    </div>
  </div>
  <!-- <div class="box7">
    <div class="text1">加速资讯</div>
    <div class="text2">关注雷电加速最新动态，了解实时加速资讯</div>
    <div class="box">
      <div class="new1">
        <div class="img">
          <div class="tag">热点资讯</div>
        </div>
        <div class="arrow" />
        <div class="beauty1" />
        <div class="beauty2" />
      </div>
      <div class="new2" />
      <div class="new3" />
    </div>
  </div> -->
  <div class="box6">
    <div class="text1">上线半年已累计用户100W+</div>
    <div class="text2">看看大家是如何评价雷电加速器</div>
    <div class="tag">
      <el-carousel indicator-position="outside">
        <el-carousel-item v-for="item in [0, 1, 2]" :key="item">
          <div style="display: flex;justify-content: space-between">
            <div class="box">
              <div class="text">{{ appraiseList[item * 2].text }}</div>
              <div class="people">
                <img v-if="item == 0" class="img" src="@/assets/images/head1.jpg">
                <img v-if="item == 1" class="img" src="@/assets/images/head2.jpg">
                <img v-if="item == 2" class="img" src="@/assets/images/head3.jpg">
                <div class="right">
                  <div class="name">{{ appraiseList[item * 2].name }}</div>
                  <div class="time">{{ appraiseList[item * 2].time }}</div>
                </div>
              </div>
            </div>
            <div class="box">
              <div class="text">{{ appraiseList[item * 2 + 1].text }}</div>
              <div class="people">
                <img v-if="item == 0" class="img" src="@/assets/images/head4.png">
                <img v-if="item == 1" class="img" src="@/assets/images/head5.png">
                <img v-if="item == 2" class="img" src="@/assets/images/head6.jpg">
                <div class="right">
                  <div class="name">{{ appraiseList[item * 2 + 1].name }}</div>
                  <div class="time">{{ appraiseList[item * 2 + 1].time }}</div>
                </div>
              </div>
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
      <!-- <div v-for="item,index in appraiseList" :key="index" class="box">
        <div class="text">{{ item.text }}</div>
        <div class="people">
          <img class="img" src="@/assets/images/head.png">
          <div class="right">
            <div class="name">{{ item.name }}</div>
            <div class="time">{{ item.time }}</div>
          </div>
        </div>
      </div> -->
    </div>
  </div>
  <el-dialog v-model="dl.show">
    <div class="captcha_dialog">
      <img class="img" :src="dl.src" alt="" @click="changeCaptcha">
      <div class="text">看不清？<span class="changeCaptcha" @click="changeCaptcha">换一张</span></div>
      <el-input v-model="dl.code" class="input" size="small" placeholder="请输入图形中的验证码" />
      <el-button style="width: 294px;" type="primary" @click="confirmDownload" @keyup.enter="confirmDownload">确认下载</el-button>
    </div>
    <template #title>
      <span style="color: #333333;">完成字符验证即可下载</span>
    </template>
  </el-dialog>
</template>
<style lang="less" scoped>
.box1 {
  width: 100%;
  min-height: calc(100vh - 98px);
  padding: 122px 0 0 200px;

  .text1 {
    font-size: 78px;
    color: #fff;
    line-height: 110px;
  }

  .text2 {
    margin: 32px 0;
    font-size: 40px;
    color: #fff;
    line-height: 56px;
  }

  .text3 {
    font-size: 24px;
    color: #fff;
    line-height: 33px;
  }

  .tag {
    width: 100%;
    margin: 72px 0 50px 0;
    display: grid;
    grid-template-columns: 300px 300px;
    grid-template-rows: 96px 96px;
    grid-gap: 20px;

    .img {
      margin-right: 24px;
      width: 44px;
      height: 44px;
    }

    .box {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 18px 32px;
      font-size: 18px;
      color: #fff;
      line-height: 25px;
      border-radius: 8px;
      border: 1px solid #979797;
      transition: all 0.3s ease-out;

      .img.hover {
        display: none;
      }

      &:hover {
        background: #ffffff;
        color: #00665d;

        .img {
          display: none;
        }

        .img.hover {
          display: block;
        }
      }
    }
  }
}

.box2 {
  background: #fff;
  width: 100%;
  color: #333333;
  padding: 100px 90px 76px 90px;

  .text1 {
    width: 100%;
    font-size: 56px;
    line-height: 78px;
    text-align: center;
  }

  .text2 {
    width: 100%;
    margin: 16px 0;
    font-size: 32px;
    line-height: 45px;
    text-align: center;
  }

  .text3 {
    width: 100%;
    font-size: 20px;
    color: #666666;
    line-height: 28px;
    text-align: center;
  }

  .tag {
    width: 100%;

    .box {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .text {
        width: 662px;

        .number {
          font-weight: bold;
          font-size: 72px;
          line-height: 87px;
          background-clip: text;
          -webkit-text-fill-color: transparent;
          background-image: linear-gradient(0deg, #00ddd5 0%, #00bdcb 100%);
        }

        .title {
          margin: 40px 0;
          font-size: 48px;
          line-height: 67px;
          font-weight: bold;
        }

        .content {
          font-size: 32px;
          line-height: 45px;
        }
      }

      .img {
        width: 648px;
        height: 520px;
      }
    }
  }
}

.box3 {
  width: 100%;
  color: #fff;
  padding: 132px 0 76px 0;
  background-color: #444d4c;

  .text1 {
    width: 100%;
    font-size: 56px;
    line-height: 78px;
    text-align: center;
  }

  .text2 {
    width: 100%;
    margin: 24px 0 108px 0;
    font-size: 32px;
    line-height: 45px;
    text-align: center;
  }

  .tag {
    display: flex;
    justify-content: center;
    width: 100%;

    .box {
      cursor: pointer;
      width: 390px;
      height: 400px;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 72px 45px 0 45px;

      .text {
        .title {
          margin-top: 32px;
          font-size: 32px;
          line-height: 45px;
          font-weight: bold;
          text-align: center;
        }

        .content {
          display: none;
        }
      }

      &:hover {
        background: rgba(255, 255, 255, 0.08);
        border-radius: 8px;
        padding-top: 53px;

        .text {
          .title {
            margin: 25px 0 12px 0;
            font-size: 24px;
            line-height: 33px;
          }

          .content {
            display: block;
            font-size: 16px;
            line-height: 22px;
          }
        }
      }
    }
  }
}

.box4 {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  color: #333;
  padding: 86px 180px 121px 180px;
  background-color: #fff;

  .text1 {
    width: 100%;
    font-size: 56px;
    line-height: 78px;
    text-align: center;
  }

  .text2 {
    width: 100%;
    margin: 12px 0 28px 0;
    font-size: 32px;
    line-height: 45px;
    text-align: center;
  }

  .text3 {
    width: 720px;
    font-size: 20px;
    color: #666666;
    line-height: 28px;
    text-align: center;
  }

  .tag {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: 260px 260px;
    grid-column-gap: 40px;
    width: 100%;

    .box {
      height: 260px;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      border-bottom: 1px solid #444d4c;

      .title {
        margin-bottom: 12px;
        font-size: 24px;
        line-height: 33px;
        font-weight: bold;
      }

      .text {
        width: 318px;
        font-size: 16px;
        color: #808080;
        line-height: 22px;
      }

      .img {
        width: 88px;
        height: 88px;
      }

      &:hover {
        color: #fff;
        background: #444d4c;
        transition: all 0.4s ease;

        .text {
          color: #fff;
        }
      }
    }
  }
}

.box5 {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  color: #333;
  padding: 60px 180px 160px 180px;
  background-color: #fff;

  .text1 {
    width: 100%;
    font-size: 56px;
    line-height: 78px;
    text-align: center;
  }

  .text2 {
    width: 100%;
    margin: 12px 0 72px 0;
    font-size: 32px;
    line-height: 45px;
    text-align: center;
  }

  .tag {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;

    .arrow {
      width: 60px;
      height: 60px;
    }

    .box {
      height: 320px;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;

      .num {
        width: 48px;
        height: 48px;
        background: linear-gradient(90deg, #00ddd5 0%, #00bdcb 100%);
        border-radius: 24px;
        font-weight: bold;
        font-size: 28px;
        color: #ffffff;
        line-height: 48px;
        text-align: center;
      }

      .img {
        width: 100px;
        height: 100px;
      }

      .down {
        cursor: pointer;
        width: 132px;
        height: 40px;
        background: linear-gradient(90deg, #00ddd5 0%, #00bdcb 100%);
        border-radius: 8px;
        font-size: 16px;
        color: #ffffff;
        line-height: 40px;
        text-align: center;
      }

      .text {
        font-size: 20px;
        line-height: 28px;
        text-align: center;
      }

      .img {
        width: 88px;
        height: 88px;
      }
    }
  }
}

.box6 {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  color: #333;
  padding: 0 180px 232px 180px;
  background-color: #fff;

  .text1 {
    width: 100%;
    font-size: 56px;
    line-height: 78px;
    text-align: center;
  }

  .text2 {
    width: 100%;
    margin: 16px 0 80px 0;
    font-size: 32px;
    line-height: 45px;
    text-align: center;
  }

  .tag {
    width: 100%;

    .box {
      width: 48%;
      height: 300px;
      padding: 38px 44px;
      border-radius: 8px;
      border: 1px solid #DFE5E5;

      .text {
        height: 140px;
        font-size: 16px;
        line-height: 26px;
      }

      .people {
        display: flex;
        margin-top: 40px;

        .img {
          width: 60px;
          height: 60px;
          margin-right: 24px;
          border-radius: 50%;
        }

        .right {
          display: flex;
          flex-direction: column;
          justify-content: space-around;

          .name {
            font-size: 20px;
            font-weight: bold;
            line-height: 28px;
          }

          .time {
            font-size: 14px;
            color: #cccccc;
            line-height: 20px;
          }
        }
      }
    }
  }
}
.box7 {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  color: #333;
  padding: 0 180px 232px 180px;
  background-color: #fff;

  .text1 {
    width: 100%;
    font-size: 56px;
    line-height: 78px;
    text-align: center;
  }

  .text2 {
    width: 100%;
    margin: 16px 0 80px 0;
    font-size: 32px;
    line-height: 45px;
    text-align: center;
  }

  .tag {
    width: 100%;

    .box {
      width: 48%;
      height: 300px;
      padding: 38px 44px;
      border-radius: 8px;
      border: 1px solid #DFE5E5;

      .text {
        height: 140px;
        font-size: 16px;
        line-height: 26px;
      }

      .people {
        display: flex;
        margin-top: 40px;

        .img {
          width: 60px;
          height: 60px;
          margin-right: 24px;
          border-radius: 50%;
        }

        .right {
          display: flex;
          flex-direction: column;
          justify-content: space-around;

          .name {
            font-size: 20px;
            font-weight: bold;
            line-height: 28px;
          }

          .time {
            font-size: 14px;
            color: #cccccc;
            line-height: 20px;
          }
        }
      }
    }
  }
}
.captcha_dialog {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 153px 24px 153px;
  --el-text-color-regular: #333333;

  .img {
    cursor: pointer;
    width: 212px;
    height: 86px;
  }

  .text {
    margin-top: 16px;
    font-size: 16px;
    color: #666666;
    line-height: 22px;

    .changeCaptcha {
      cursor: pointer;
      color: #2E8AFF;
    }
  }

  .input {
    margin: 24px 0;
    width: 212px;
    height: 36px;
  }
}
</style>
